import { Theme, ThemeContext } from "assets";
import { Error404 } from "atoms";
import { PageLayout } from "organisms";
import * as React from "react";

const NotFoundPage = () => {
  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Black,
        fontType: "",
      }}
    >
      <PageLayout theme={Theme.Black} title="Page Not Found">
        <Error404 />
      </PageLayout>
    </ThemeContext.Provider>
  );
};

export default NotFoundPage;
